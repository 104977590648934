var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("div", { staticClass: "container social-container" }, [
        _c("div", { staticClass: "row mb-5" }, [
          _vm._m(4),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-8 p-0" },
            [_c("VE-Instagram-Images")],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(5),
      _vm._v(" "),
      _c("Shopfinder"),
      _vm._v(" "),
      _c("div", { staticClass: "container buy-puelleken-container" }, [
        _c("div", { staticClass: "row my-5" }, [
          _vm._m(6),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-7 ml-auto d-flex flex-column shop-logos",
            },
            [
              _vm._m(7),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex shop-logos-row" }, [
                _vm._m(8),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "rewe-link",
                    attrs: {
                      href: "https://shop.rewe.de/productList?brand=Pülleken",
                      target: "_blank",
                      rel: "noopener",
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "rewe-logo",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 105 53",
                        },
                      },
                      [
                        _c(
                          "g",
                          { attrs: { fill: "none", "fill-rule": "evenodd" } },
                          [
                            _c("rect", {
                              attrs: {
                                fill: "#CC071E",
                                width: "105",
                                height: "53",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "g",
                              { attrs: { transform: "translate(0 8)" } },
                              [
                                _c("polygon", {
                                  attrs: {
                                    fill: "#CC071E",
                                    points:
                                      "0 36.6509434 105 36.6509434 105 0 0 0",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M96.066 5.984c1.387 0 2.043.658 2.043 2.046v1.792c0 1.352-.656 2.01-2.043 2.01h-7.075v3.582h5.981c1.386 0 2.043.658 2.043 2.047v1.79c0 1.353-.657 2.01-2.043 2.01h-5.981v3.73h8.17c1.386 0 2.042.657 2.042 2.046v1.791c0 1.353-.656 2.01-2.042 2.01H83.337c-1.35 0-2.006-.657-2.006-2.01V8.03c0-1.388.656-2.046 2.006-2.046Zm-43.368 0c1.605 0 2.297.584 2.516 1.973l2.007 12.209h.072l2.48-12.209c.293-1.389.985-1.973 2.517-1.973h3.1c1.533 0 2.226.584 2.518 1.973l2.517 12.209h.072l2.007-12.209c.218-1.389.912-1.973 2.516-1.973h2.553c1.204 0 1.934.548 1.934 1.644 0 .22-.037.512-.11.805L74.62 28.828c-.328 1.353-.985 2.01-2.7 2.01h-2.99c-1.532 0-2.225-.584-2.517-1.973l-2.735-13.342h-.073l-2.736 13.342c-.292 1.389-.985 1.974-2.517 1.974h-2.99c-1.715 0-2.372-.658-2.7-2.011L47.883 8.433a3.506 3.506 0 0 1-.11-.805c0-1.096.693-1.644 1.934-1.644Zm-8.79 0c1.385 0 2.042.658 2.042 2.046v1.792c0 1.352-.657 2.01-2.043 2.01h-7.076v3.582h5.982c1.386 0 2.042.658 2.042 2.047v1.79c0 1.353-.656 2.01-2.042 2.01H36.83v3.73H45c1.386 0 2.043.657 2.043 2.046v1.791c0 1.353-.657 2.01-2.043 2.01H31.177c-1.35 0-2.006-.657-2.006-2.01V8.03c0-1.388.657-2.046 2.006-2.046Zm-28.597 0c7.66 0 10.577 2.924 10.577 7.858 0 2.924-1.495 5.373-4.924 6.652l5.326 7.896c.291.475.437.877.437 1.206 0 .84-.875 1.243-1.933 1.243h-4.34c-1.24 0-1.934-.585-2.736-1.974l-4.304-7.42h-.073v7.383c0 1.353-.657 2.01-2.043 2.01H7.833c-1.35 0-2.006-.657-2.006-2.01V8.03c0-1.388.657-2.046 2.006-2.046Zm-.365 5.702H13.34v5.19h1.605c2.152 0 3.064-.914 3.064-2.595 0-1.682-.912-2.595-3.064-2.595Z",
                                    fill: "#FFFFFE",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(9),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "header-container" }, [
      _c("img", {
        attrs: {
          src: require("../images/200812_Puellecken_Walk_v7_16by9_alpha_00249.png"),
          width: "768",
          height: "432",
          alt: "Komm wir trinken noch ein Pülleken",
          title: "Komm wir trinken noch ein Pülleken",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "note-wrapper container helle-puelleken" },
      [
        _c("img", {
          staticClass: "img-fluid center-img picker note",
          staticStyle: { "z-index": "2" },
          attrs: {
            src: require("../images/21636_Puelleken_zettel_01.png"),
            alt: "Wörterbuch: das Pülleken",
            title: "Wörterbuch: das Pülleken",
            width: "413",
            height: "400",
            loading: "lazy",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex flex-border-wrapper borderLine" }, [
          _c("div", { staticClass: "col-md-3" }, [
            _c("img", {
              staticClass: "center-img pulleken-einzel-flasche",
              attrs: {
                src: require("../images/VE_Puelleken_Flasche_220.png"),
                srcset:
                  require("../images/VE_Puelleken_Flasche_220.png") +
                  " 1x, " +
                  require("../images/VE_Puelleken_Flasche.png") +
                  " 2x",
                width: "220",
                alt: "Flasche Pülleken",
                title: "Flasche Pülleken",
                loading: "lazy",
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pt-5 px-lg-5 col-md-8 text-center" }, [
            _c("h2", { staticClass: "brand mt-md-5 px-5 px-md-0" }, [
              _vm._v("Pülleken"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "mt-md-5 mt-3 pl-md-5 textColor" }, [
              _vm._v(
                "\n                    Das helle Pülleken ist das Bier für den besonderen Genuss. Ein sehr süffiges Hell-Bier, das die\n                    goldene Mitte zwischen den herberen Pilsener\n                    Bieren und den malzig-süßlichen Bieren bayerischer Herkunft trifft. Gebraut nach dem deutschen\n                    Reinheitsgebot.\n                "
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "d-md-flex d-none justify-content-center w-100" },
      [
        _c("img", {
          staticClass: "schild",
          attrs: {
            src: require("../images/schild.png"),
            alt: "VELTINS",
            title: "VELTINS",
            loading: "lazy",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "container my-5" }, [
      _c("div", { staticClass: "quote-wrapper" }, [
        _c("div", { staticClass: "col-md-4" }, [
          _c("img", {
            staticClass: "img-fluid center-img",
            attrs: {
              src: require("../images/Illu_o_Schild_RGB_B_15.png"),
              alt: "Gute Laune & Lebensfreude",
              title: "Gute Laune & Lebensfreude",
              width: "450",
              height: "180",
              loading: "lazy",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-4", attrs: { id: "quoteVeltins" } }, [
          _c("h2", { attrs: { id: "titleNormal" } }, [
            _vm._v("GUTE LAUNE & LEBENSFREUDE"),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "textColor" }, [
            _vm._v(
              "\n                    Das helle Pülleken von VELTINS ist eine Marke, die gute Laune und Lebensfreude verbreitet. Das\n                    märchenhafte Motiv vermittelt eine heitere,\n                    gesellige Stimmung.\n                "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "col-md-4 d-flex justify-content-center align-items-center",
      },
      [
        _c("div", { staticClass: "text-center socialRotation" }, [
          _c("div", { staticClass: "titleSocialMedia" }, [
            _c("h2", [
              _c("span", { staticClass: "brand" }, [_vm._v("Pülleken")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { id: "textColorSocialMedia" } }, [
            _vm._v("on Social Media"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-center social-icons-wrapper mt-3",
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.facebook.com/puelleken/",
                    target: "_blank",
                    rel: "noopener",
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../images/VE_Puelleken_Icon_Social_1.png"),
                      alt: "facebook",
                      title: "facebook",
                      loading: "lazy",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("div", { attrs: { id: "boldLine" } }),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.instagram.com/puelleken/",
                    target: "_blank",
                    rel: "noopener",
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../images/VE_Puelleken_Icon_Social_2.png"),
                      alt: "instagram",
                      title: "instagram",
                      loading: "lazy",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "row container our-puelleken-container mb-0 borderLine" },
      [
        _c("div", { staticClass: "col-lg-5 col-md-6 col-12" }, [
          _c("div", { staticClass: "d-flex align-items-center mb-4" }, [
            _c("h2", { staticClass: "our-puelleken" }, [
              _vm._v("Unser "),
              _c("span", { staticClass: "brand" }, [_vm._v("Pülleken")]),
            ]),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-md-left mt-3 mt-md-0 textColor" }, [
            _vm._v(
              "\n                Unser helles Pülleken ist im Handel als 4er- und 6er-Träger erhältlich und seit dem 01. Juli auch im\n                24x0,33l Mehrwegkasten.\n            "
            ),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "btn btn-primary btn-block mx-0 d-md-block d-none mt-5 button_optional",
              attrs: {
                type: "button",
                "data-toggle": "modal",
                "data-target": "#inhaltsstoffeModal",
              },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "d-flex flex-row justify-content-center align-items-center",
                },
                [
                  _c("img", {
                    staticClass: "mr-3",
                    attrs: {
                      src: require("../images/Icon_Info.svg"),
                      width: "20",
                      height: "20",
                      alt: "Info",
                      title: "Info",
                      loading: "lazy",
                    },
                  }),
                  _vm._v(
                    "\n                    Inhaltsstoffe\n                "
                  ),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-7 col-md-6 col-12 traeger-wrapper" }, [
          _c("img", {
            staticClass: "center-img viererTraeger socialRotation",
            attrs: {
              src: require("../images/VE_Puelleken_Gebinde_4er.png"),
              alt: "4er Träger",
              title: "4er Träger",
              loading: "lazy",
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "center-img secherTraeger socialRotation",
            attrs: {
              src: require("../images/VE_Puelleken_Gebinde_6er.png"),
              alt: "6er Träger",
              title: "6er Träger",
              loading: "lazy",
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "center-img kasten-gebinde socialRotation",
            attrs: {
              src: require("../images/VE_Puelleken_Gebinde_Kasten.png"),
              alt: "24er Kasten",
              title: "24er Kasten",
              loading: "lazy",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "d-md-none px-0" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-block my-3 button_optional",
                attrs: {
                  type: "button",
                  "data-toggle": "modal",
                  "data-target": "#inhaltsstoffeModal",
                },
              },
              [_vm._v("\n                    Inhaltsstoffe\n                ")]
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "col-lg-5 col-xl-4 col-12 d-flex justify-content-center" },
      [
        _c("div", { staticClass: "text-center socialRotation" }, [
          _c("div", { staticClass: "titleSocialMedia" }, [
            _c("span", { staticClass: "brand" }, [_vm._v("Pülleken")]),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "mt-3 buy-puelleken" }, [
            _vm._v(
              "\n                        Hier können Sie unser helles Pülleken bequem online bestellen:\n                    "
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "d-flex shop-logos-row" }, [
      _c(
        "a",
        {
          staticClass: "flaschenpost-link",
          attrs: {
            href: "https://amzn.eu/d/0yFDhl8",
            target: "_blank",
            rel: "noopener",
          },
        },
        [
          _c("img", {
            staticClass: "amazon-logo",
            attrs: {
              src: require("../images/available_at_amazon_DE_logo_stacked_RGB_SQUID.png"),
              alt: "amazon.de",
              title: "amazon.de",
              loading: "lazy",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "flaschenpost-link",
          attrs: {
            href: "https://www.flaschenpost.de/puelleken ",
            target: "_blank",
            rel: "noopener",
          },
        },
        [
          _c("img", {
            staticClass: "flaschenpost-logo",
            attrs: {
              src: require("../images/logo-flaschenpost.png"),
              alt: "flaschenpost.de",
              title: "flaschenpost.de",
              loading: "lazy",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      {
        staticClass: "hofladen-link",
        attrs: {
          href: "https://www.hofladen-sauerland.de/getraenke/bier/veltins-helles-puelleken--",
          target: "_blank",
          rel: "noopener",
        },
      },
      [
        _c("img", {
          staticClass: "hofladen-sauerland-logo",
          attrs: {
            src: require("../images/hofladen.png"),
            alt: "hofladen-sauerland.de",
            title: "hofladen-sauerland.de",
            loading: "lazy",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass: "modal",
        attrs: { id: "inhaltsstoffeModal", tabindex: "-1", role: "dialog" },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog modal-xl", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                {
                  staticClass: "textColor text-left borderLine",
                  attrs: { id: "nutritons" },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 d-flex justify-content-center mb-5",
                      },
                      [
                        _c("div", { staticClass: "socialRotation" }, [
                          _c("h2", { staticClass: "text-center" }, [
                            _c("span", { staticClass: "brand" }, [
                              _vm._v("Pülleken"),
                            ]),
                            _c("br"),
                            _vm._v(
                              "\n                                    Inhaltsstoffe\n                                "
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-md-4" }, [
                      _c("h3", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(
                          "\n                                Bezeichnung\n                            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "textColor" }, [_vm._v("Bier")]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("h3", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v("Zutaten"),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "textColor" }, [
                        _vm._v(
                          "\n                                Wasser,\n                                "
                        ),
                        _c("u", [_vm._v("Gerstenmalz")]),
                        _vm._v(", Hopfen\n                            "),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-md-8" }, [
                      _c(
                        "h3",
                        {
                          staticStyle: {
                            "font-weight": "bold",
                            "margin-bottom": "0",
                          },
                        },
                        [
                          _vm._v(
                            "\n                                Nährwerte pro 100ml\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("table", { staticClass: "table textColor" }, [
                        _c("tbody", [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "padding-top": "0",
                                  "vertical-align": "middle",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                            Brennwert\n                                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                "\n                                            179 KJ\n                                            "
                              ),
                              _c("br"),
                              _vm._v(
                                "43 kcal\n                                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [_vm._v("Fett")]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                "\n                                            < 0,5 g\n                                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                "\n                                            Fett davon gesättigte Fettsäuren\n                                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                "\n                                            < 0,1 g\n                                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [_vm._v("Kohlenhydrate")]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                "\n                                            3,1 g\n                                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [_vm._v("davon Zucker")]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                "\n                                            < 0,5 g\n                                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [_vm._v("Eiweiß")]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                "\n                                            0,5 g\n                                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [_vm._v("Salz")]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                "\n                                            < 0,01 g\n                                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [_vm._v("Alkoholgehalt")]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                "\n                                            5,2 % vol\n                                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [_vm._v("Stammwürze")]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                "\n                                            11° Plato\n                                        "
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-center w-100" },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-primary btn-block mx-0 button_optional",
                            staticStyle: { width: "30%", padding: "10px 20px" },
                            attrs: {
                              type: "button",
                              "data-toggle": "modal",
                              "data-target": "#inhaltsstoffeModal",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "d-flex justify-content-center align-items-center",
                              },
                              [
                                _c("span", { staticClass: "mr-3" }, [
                                  _vm._v("X"),
                                ]),
                                _vm._v(
                                  "\n                                    ZURÜCK\n                                "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }