<template>
    <div class="container buy-puelleken-container" id="buy-puelleken">
        <div class="row">
            <div class="col-lg-5 col-xl-4 col-12 d-flex justify-content-center mb-5">
                <div class="text-center socialRotation">
                    <div class="titleSocialMedia">
                        <span class="brand">Pülleken</span>
                    </div>
                    <p class="mt-3 buy-puelleken">
                        Hier können Sie unser helles Pülleken kaufen:
                        <div class="btn-group-toggle">
                            <label class="btn btn-outline-primary w-auto text-uppercase mr-3" :class="{active: PUELLEKEN_STORE_ID == checkedShopType}">
                                <input type="radio" :value="PUELLEKEN_STORE_ID" name="shopType" v-model="checkedShopType"/> Händler
                            </label>
                            <label class="btn btn-outline-primary w-auto text-uppercase" :class="{active: PUELLEKEN_GASTRO_ID == checkedShopType}">
                                <input type="radio" :value="PUELLEKEN_GASTRO_ID" name="shopType" v-model="checkedShopType"/> Gastronomie
                            </label>
                        </div>
                    </p>
                    <gmap-autocomplete
                        v-if="cookiesEnabled"
                        placeholder="PLZ oder Ort"
                        @place_changed="setPlace"
                        :select-first-on-enter="true"
                        class="storefinder-search-input"
                        :options="{
                            componentRestrictions: { country: 'de' },
                            strictBounds: true,
                            fields: ['geometry.location'],
                            types: ['geocode'],
                        }"
                    >
                    </gmap-autocomplete>
                </div>
            </div>

            <div class="col-lg-7 col-12 ml-auto storefinder-col">
                <gmap-map v-if="cookiesEnabled" :center="center" :zoom="zoom" :markers="markers" :options="mapOptions" style="width: 100%; height: 400px;" class="storefinder">
                    <gmap-cluster :zoomOnClick="true">
                        <gmap-marker :key="index" v-for="(m, index) in markers" :position="m.position" @click="toggleInfoWindow(m, index)" :icon="m.icon"></gmap-marker>
                        <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen = false" :content="infoContent">
                        </gmap-info-window>
                    </gmap-cluster>
                </gmap-map>
                <div v-else>
                    Bitte die <a href="#cookieconsent" style="text-decoration: underline">Cookie Einstellungen</a> anpassen.
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import '../scss/_variables.scss';

.buy-puelleken-container {
    padding-left: 0;
    padding-right: 0;
}
.storefinder-col {
    padding-left: 0;
    padding-right: 0;
}
.storefinder {
    border: $container-border-width solid $container-border-color;

    .gm-style-iw {
        a {
            color: $primary;
        }
    }
}
.storefinder-search-input {
    width: 100%;
    border: 5px solid $container-border-color;
    padding: .5em .8em;
    font-size: 20px;
    transition: transform 0.3s ease-out;

    &:focus {
        outline: none;
        @media only screen and (min-width: 769px) {
            transform: rotate(3deg);
        }
    }
}
</style>

<script>
import Vue from 'vue'

const apiUrl = 'https://secure.veltins.de/shopfinder/api'
const searchRadius = 5

const PUELLEKEN_STORE_ID = 5
const PUELLEKEN_GASTRO_ID = 6
const SHOPFINDER_ICON = {scaledSize: {width: 42, height: 64}, url: require('../images/VE_Puelleken_Pin_Shopfinder.png')};
const GASTROFINDER_ICON = {scaledSize: {width: 42, height: 64}, url: require('../images/VE_Puelleken_Pin_Shopfinder_Gastro.png')};

export default Vue.component('Shopfinder', {
    created() {
        this.PUELLEKEN_STORE_ID = PUELLEKEN_STORE_ID;
        this.PUELLEKEN_GASTRO_ID = PUELLEKEN_GASTRO_ID;
    },
    data: function () {
        return {
            mapOptions: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false,
                clickableIcons: false,
            },
            // default to Grevenstein to keep it simple
            center: { lat: 51.3033201, lng: 8.1213589 },
            zoom: 12,
            markers: [],
            currentPlace: null,
            location: null,
            // Infowindow
            infoContent: null,
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,
            //optional: offset infowindow so it visually sits nicely on top of our marker
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -65,
                },
                content: '',
            },
            checkedShopType: PUELLEKEN_STORE_ID,
        }
    },
    watch: {
        // whenever checkedShopType changes, this function will run
        checkedShopType: function () {
            if(this.location){
                this.search();
            }
        }
    },
    mounted: function () {
        // this.geolocate()
    },

    methods: {
        // receives a place object via the autocomplete component
        setPlace: function (place) {
            if (place.geometry) {
                this.location = place.geometry.location
                this.setCenter()
                this.search()
            }
        },
        setCenter: function () {
            this.center = {
                lat: this.location.lat(),
                lng: this.location.lng(),
            }
            this.zoom = 11
        },
        addMarker: function () {
            if (this.currentPlace) {
                const marker = {
                    lat: this.currentPlace.lat,
                    lng: this.currentPlace.lng,
                }
                this.markers.push({ position: marker })
                this.center = marker
                this.currentPlace = null
            }
        },
        clearAllMarkers: function () {
            this.markers = []
        },
        addShopMarker: function (location, infoText) {
            this.markers.push({ position: location, infoText: infoText, icon: SHOPFINDER_ICON})
        },
        addGastroMarker: function (location, infoText) {
            this.markers.push({ position: location, infoText: infoText, icon: GASTROFINDER_ICON})
        },
        getMarkerType: function(shop){
            if(PUELLEKEN_STORE_ID == shop.location_type_id){
                return this.addShopMarker;
            }else if(PUELLEKEN_GASTRO_ID == shop.location_type_id){
                return this.addGastroMarker;
            }
        },
        addMarkers: function (response) {
            this.clearAllMarkers();
            for (var i = 0; i < response.data.data.length; i++) {
                var shop = response.data.data[i]
                this.getMarkerType(shop)({ lat: shop.latitude, lng: shop.longitude }, this.buildInfoWindow(shop))
            }
        },
        buildInfoWindow: function (shop) {
            var info = '<b>' + shop.name + '</b><br><br>' + shop.street + ' ' + shop.housenumber + '<br>' + shop.zip + ' ' + shop.city
            if (shop.www && shop.www.indexOf('http') > -1) {
                info += "<br><br><a href='" + shop.www + "' target='_blank'>Webseite</a>"
            }
            return info
        },
        toggleInfoWindow: function (marker, idx) {
            this.infoWindowPos = marker.position
            this.infoOptions.content = marker.infoText

            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen
            }
            //if different marker set infowindow to open and reset current marker index
            else {
                this.infoWinOpen = true
                this.currentMidx = idx
            }
        },
        geolocate: function () {
            var self = this
            navigator.geolocation.getCurrentPosition(function (position) {
                self.currentPlace = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }

                self.zoom = 10
                self.location = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }
                self.search()
            })
        },
        getShopType: function () {
            return this.checkedShopType
        },
        buildSearchUrl: function () {
            var location = this.location.toUrlValue ? this.location.toUrlValue() : this.location.lat + ',' + this.location.lng
            return [apiUrl, this.getShopType(), location, searchRadius].join('/')
            // return apiUrl
        },
        search: function () {
            if(!this.checkedShopType){
                return;
            }
            var url = this.buildSearchUrl()
            var self = this
            this.axios
                .get(url, {
                    params: {
                        getAll: 1,
                    },
                })
                .then(self.addMarkers)
                .catch(function (error) {
                    console.log(error)
                })
                .then(function () {
                    // always executed
                })
        },
    },
    computed:{
        cookiesEnabled: ()=>{
            return document.cookie.indexOf('_vcc') > -1
        }
    }
})
</script>