<template>
    <div>
        <div class="header-container">
            <img src="../images/200812_Puellecken_Walk_v7_16by9_alpha_00249.png" width="768" height="432"
                alt="Komm wir trinken noch ein Pülleken" title="Komm wir trinken noch ein Pülleken" />
        </div>

        <div class="note-wrapper container helle-puelleken">
            <img src="../images/21636_Puelleken_zettel_01.png" class="img-fluid center-img picker note"
                alt="Wörterbuch: das Pülleken" title="Wörterbuch: das Pülleken" width="413" height="400" style="z-index: 2;"
                loading="lazy" />

            <div class="d-flex flex-border-wrapper borderLine">
                <div class="col-md-3">
                    <img src="../images/VE_Puelleken_Flasche_220.png"
                        srcset="../images/VE_Puelleken_Flasche_220.png 1x, ../images/VE_Puelleken_Flasche.png 2x"
                        class="center-img pulleken-einzel-flasche" width="220" alt="Flasche Pülleken"
                        title="Flasche Pülleken" loading="lazy" />
                </div>
                <div class="pt-5 px-lg-5 col-md-8 text-center">
                    <h2 class="brand mt-md-5 px-5 px-md-0">Pülleken</h2>
                    <p class="mt-md-5 mt-3 pl-md-5 textColor">
                        Das helle Pülleken ist das Bier für den besonderen Genuss. Ein sehr süffiges Hell-Bier, das die
                        goldene Mitte zwischen den herberen Pilsener
                        Bieren und den malzig-süßlichen Bieren bayerischer Herkunft trifft. Gebraut nach dem deutschen
                        Reinheitsgebot.
                    </p>
                </div>
            </div>
        </div>
        <div class="d-md-flex d-none justify-content-center w-100">
            <img src="../images/schild.png" class="schild" alt="VELTINS" title="VELTINS" loading="lazy" />
        </div>
        <div class="container my-5">
            <div class="quote-wrapper">
                <div class="col-md-4">
                    <img src="../images/Illu_o_Schild_RGB_B_15.png" class="img-fluid center-img"
                        alt="Gute Laune &amp; Lebensfreude" title="Gute Laune &amp; Lebensfreude" width="450" height="180"
                        loading="lazy" />
                </div>

                <div class="col-md-4" id="quoteVeltins">
                    <h2 id="titleNormal">GUTE LAUNE &amp; LEBENSFREUDE</h2>
                    <p class="textColor">
                        Das helle Pülleken von VELTINS ist eine Marke, die gute Laune und Lebensfreude verbreitet. Das
                        märchenhafte Motiv vermittelt eine heitere,
                        gesellige Stimmung.
                    </p>
                </div>
            </div>
        </div>
        <div class="container social-container">
            <div class="row mb-5">
                <div class="col-md-4 d-flex justify-content-center align-items-center">
                    <div class="text-center socialRotation">
                        <div class="titleSocialMedia">
                            <h2>
                                <span class="brand">Pülleken</span>
                            </h2>
                        </div>
                        <div id="textColorSocialMedia">on Social Media</div>
                        <div class="d-flex justify-content-center social-icons-wrapper mt-3">
                            <a href="https://www.facebook.com/puelleken/" target="_blank" rel="noopener">
                                <img src="../images/VE_Puelleken_Icon_Social_1.png" alt="facebook" title="facebook"
                                    loading="lazy" />
                            </a>
                            <div id="boldLine"></div>
                            <a href="https://www.instagram.com/puelleken/" target="_blank" rel="noopener">
                                <img src="../images/VE_Puelleken_Icon_Social_2.png" alt="instagram" title="instagram"
                                    loading="lazy" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 p-0">
                    <VE-Instagram-Images />
                </div>
            </div>
        </div>

        <div class="row container our-puelleken-container mb-0 borderLine">
            <div class="col-lg-5 col-md-6 col-12">
                <div class="d-flex align-items-center mb-4">
                    <h2 class="our-puelleken">Unser <span class="brand">Pülleken</span></h2>
                </div>
                <p class="text-md-left mt-3 mt-md-0 textColor">
                    Unser helles Pülleken ist im Handel als 4er- und 6er-Träger erhältlich und seit dem 01. Juli auch im
                    24x0,33l Mehrwegkasten.
                </p>
                <button type="button" class="btn btn-primary btn-block mx-0 d-md-block d-none mt-5 button_optional"
                    data-toggle="modal" data-target="#inhaltsstoffeModal">
                    <span class="d-flex flex-row justify-content-center align-items-center">
                        <img src="../images/Icon_Info.svg" width="20" height="20" class="mr-3" alt="Info" title="Info"
                            loading="lazy" />
                        Inhaltsstoffe
                    </span>
                </button>
            </div>

            <div class="col-lg-7 col-md-6 col-12 traeger-wrapper">
                <img src="../images/VE_Puelleken_Gebinde_4er.png" alt="4er Träger" title="4er Träger"
                    class="center-img viererTraeger socialRotation" loading="lazy" />

                <img src="../images/VE_Puelleken_Gebinde_6er.png" alt="6er Träger" title="6er Träger"
                    class="center-img secherTraeger socialRotation" loading="lazy" />

                <img src="../images/VE_Puelleken_Gebinde_Kasten.png" alt="24er Kasten" title="24er Kasten"
                    class="center-img kasten-gebinde socialRotation" loading="lazy" />

                <div class="d-md-none px-0">
                    <button type="button" class="btn btn-primary btn-block my-3 button_optional" data-toggle="modal"
                        data-target="#inhaltsstoffeModal">
                        Inhaltsstoffe
                    </button>
                </div>
            </div>
        </div>

        <Shopfinder />

        <div class="container buy-puelleken-container">
            <div class="row my-5">
                <div class="col-lg-5 col-xl-4 col-12 d-flex justify-content-center">
                    <div class="text-center socialRotation">
                        <div class="titleSocialMedia">
                            <span class="brand">Pülleken</span>
                        </div>
                        <p class="mt-3 buy-puelleken">
                            Hier können Sie unser helles Pülleken bequem online bestellen:
                        </p>
                    </div>
                </div>

                <div class="col-12 col-lg-7 ml-auto d-flex flex-column shop-logos">
                    <div class="d-flex shop-logos-row">
                        <a href="https://amzn.eu/d/0yFDhl8" target="_blank" rel="noopener" class="flaschenpost-link">
                            <img src="../images/available_at_amazon_DE_logo_stacked_RGB_SQUID.png" class="amazon-logo"
                                alt="amazon.de" title="amazon.de" loading="lazy" />
                        </a>
                        <a href="https://www.flaschenpost.de/puelleken " target="_blank" rel="noopener"
                            class="flaschenpost-link">
                            <img src="../images/logo-flaschenpost.png" class="flaschenpost-logo" alt="flaschenpost.de"
                                title="flaschenpost.de" loading="lazy" />
                        </a>
                    </div>
                    <div class="d-flex shop-logos-row">
                        <a href="https://www.hofladen-sauerland.de/getraenke/bier/veltins-helles-puelleken--"
                            target="_blank" rel="noopener" class="hofladen-link">
                            <img src="../images/hofladen.png" class="hofladen-sauerland-logo" alt="hofladen-sauerland.de"
                                title="hofladen-sauerland.de" loading="lazy" />
                        </a>
                        <a href="https://shop.rewe.de/productList?brand=Pülleken" target="_blank" rel="noopener"
                            class="rewe-link">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 53" class="rewe-logo">
                                <g fill="none" fill-rule="evenodd">
                                    <rect fill="#CC071E" width="105" height="53" />
                                    <g transform="translate(0 8)">
                                        <polygon fill="#CC071E" points="0 36.6509434 105 36.6509434 105 0 0 0" />
                                        <path
                                            d="M96.066 5.984c1.387 0 2.043.658 2.043 2.046v1.792c0 1.352-.656 2.01-2.043 2.01h-7.075v3.582h5.981c1.386 0 2.043.658 2.043 2.047v1.79c0 1.353-.657 2.01-2.043 2.01h-5.981v3.73h8.17c1.386 0 2.042.657 2.042 2.046v1.791c0 1.353-.656 2.01-2.042 2.01H83.337c-1.35 0-2.006-.657-2.006-2.01V8.03c0-1.388.656-2.046 2.006-2.046Zm-43.368 0c1.605 0 2.297.584 2.516 1.973l2.007 12.209h.072l2.48-12.209c.293-1.389.985-1.973 2.517-1.973h3.1c1.533 0 2.226.584 2.518 1.973l2.517 12.209h.072l2.007-12.209c.218-1.389.912-1.973 2.516-1.973h2.553c1.204 0 1.934.548 1.934 1.644 0 .22-.037.512-.11.805L74.62 28.828c-.328 1.353-.985 2.01-2.7 2.01h-2.99c-1.532 0-2.225-.584-2.517-1.973l-2.735-13.342h-.073l-2.736 13.342c-.292 1.389-.985 1.974-2.517 1.974h-2.99c-1.715 0-2.372-.658-2.7-2.011L47.883 8.433a3.506 3.506 0 0 1-.11-.805c0-1.096.693-1.644 1.934-1.644Zm-8.79 0c1.385 0 2.042.658 2.042 2.046v1.792c0 1.352-.657 2.01-2.043 2.01h-7.076v3.582h5.982c1.386 0 2.042.658 2.042 2.047v1.79c0 1.353-.656 2.01-2.042 2.01H36.83v3.73H45c1.386 0 2.043.657 2.043 2.046v1.791c0 1.353-.657 2.01-2.043 2.01H31.177c-1.35 0-2.006-.657-2.006-2.01V8.03c0-1.388.657-2.046 2.006-2.046Zm-28.597 0c7.66 0 10.577 2.924 10.577 7.858 0 2.924-1.495 5.373-4.924 6.652l5.326 7.896c.291.475.437.877.437 1.206 0 .84-.875 1.243-1.933 1.243h-4.34c-1.24 0-1.934-.585-2.736-1.974l-4.304-7.42h-.073v7.383c0 1.353-.657 2.01-2.043 2.01H7.833c-1.35 0-2.006-.657-2.006-2.01V8.03c0-1.388.657-2.046 2.006-2.046Zm-.365 5.702H13.34v5.19h1.605c2.152 0 3.064-.914 3.064-2.595 0-1.682-.912-2.595-3.064-2.595Z"
                                            fill="#FFFFFE" />
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="inhaltsstoffeModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="textColor text-left borderLine" id="nutritons">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center mb-5">
                                <div class="socialRotation">
                                    <h2 class="text-center">
                                        <span class="brand">Pülleken</span><br />
                                        Inhaltsstoffe
                                    </h2>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <h3 style="font-weight: bold;">
                                    Bezeichnung
                                </h3>

                                <p class="textColor">Bier</p>
                                <br />
                                <h3 style="font-weight: bold;">Zutaten</h3>
                                <p class="textColor">
                                    Wasser,
                                    <u>Gerstenmalz</u>, Hopfen
                                </p>
                                <br />
                            </div>

                            <div class="col-12 col-md-8">
                                <h3 style="font-weight: bold; margin-bottom: 0;">
                                    Nährwerte pro 100ml
                                </h3>

                                <table class="table textColor">
                                    <tbody>
                                        <tr>
                                            <td style="padding-top: 0; vertical-align: middle;">
                                                Brennwert
                                            </td>
                                            <td class="text-right">
                                                179 KJ
                                                <br />43 kcal
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Fett</td>
                                            <td class="text-right">
                                                &lt;&nbsp;0,5 g
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                Fett davon gesättigte Fettsäuren
                                            </td>
                                            <td class="text-right">
                                                &lt;&nbsp;0,1&nbsp;g
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Kohlenhydrate</td>
                                            <td class="text-right">
                                                3,1&nbsp;g
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>davon Zucker</td>
                                            <td class="text-right">
                                                &lt;&nbsp;0,5&nbsp;g
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Eiweiß</td>
                                            <td class="text-right">
                                                0,5&nbsp;g
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Salz</td>
                                            <td class="text-right">
                                                &lt;&nbsp;0,01&nbsp;g
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Alkoholgehalt</td>
                                            <td class="text-right">
                                                5,2&nbsp;%&nbsp;vol
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Stammwürze</td>
                                            <td class="text-right">
                                                11°&nbsp;Plato
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-flex justify-content-center w-100">
                                <button type="button" class="btn btn-primary btn-block mx-0 button_optional"
                                    style="width: 30%; padding: 10px 20px;" data-toggle="modal"
                                    data-target="#inhaltsstoffeModal">
                                    <span class="d-flex justify-content-center align-items-center">
                                        <span class="mr-3">X</span>
                                        ZURÜCK
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'

export default Vue.component('MainPage', {
    data: function () {
        return {}
    },
    mounted: function () {
        if (this.$route.path == '/shopfinder') {
            setTimeout(() => {
                var el = document.getElementById('buy-puelleken')
                if (el) {
                    window.scrollTo(0, el.offsetTop)
                }
            })
        }

        // /**
        //  * Lade alle Elemente mit einem data-gifsrc Attribute
        //  * Preload den Inhalt von gifsrc, wenn die Daten geladen sind ersetze den Original src mit dem gifsrc
        //  * Wenn das GIF Bild angezeigt wird, dann warte data-wait Millisekunden und setze das Bild zurück auf das Original src
        //  */
        // var showimage = function(){
        //     this.onload = undefined;
        //     if(window.co && window.co.age){
        //         // Der User hat dem Age-Gate bereits zugestimmt
        //         gifimg.src = this.src;
        //         setOrigImg();
        //     }else{
        //             // Der User hat dem Age-Gate noch nicht zugestimmt.
        //             // Warte auf das Events dass das Age-Gate geschlossen wurde
        //             window.addEventListener(
        //             'consentCloseAfter',
        //             function(e) {
        //                 gifimg.src = this.src;
        //                 setOrigImg();
        //             }.bind(this)
        //         )
        //     }
        // }

        // var setOrigImg = function(){
        //     window.setTimeout(function(){
        //         gifimg.src = orgimg.src;
        //     }, parseInt(gifimg.getAttribute('data-wait')));
        // }

        // var gifs = document.querySelectorAll('[data-gifsrc]');
        // for(var i=0, len=gifs.length; i<len; i++){
        //     var gifimg = gifs[i],
        //         gifsrc = gifimg.getAttribute('data-gifsrc');
        //     gifimg.orgsrc = gifimg.src;

        //     // Preload gifimage
        //     var img = new Image();
        //     img.src = gifsrc;
        //     // Preload das Original Bild
        //     var orgimg = new Image();
        //     orgimg.src = gifimg.orgsrc;

        //     if(img.complete){
        //         showimage.apply(img);
        //     }else{
        //         img.addEventListener('load', showimage);
        //     }
        // }
        // Ende GIF Animation

        // window.addEventListener(
        //     'consentCloseAfter',
        //     function(e) {
        //         this.$refs.bier.classList.add('show')
        //     }.bind(this)
        // )
    },
})
</script>

<style lang="scss" scoped>
.shop-logos {
    gap: 30px;

    @media only screen and (min-width: 768px) {
        gap: 60px;
    }
}

.shop-logos-row {
    gap: 30px;
    flex-flow: column;
    justify-items: center;
    align-items: center;

    @media only screen and (min-width: 768px) {
        gap: 60px;
        flex-flow: row;
    }

    a {
        flex: 3;
        display: block;

        &.rewe-link {
            flex: 2
        }
    }
}

.amazon-logo {
    width: 100%;
    max-width: 300px;
}

.hofladen-sauerland-logo {
    width: 100%;
    max-width: 300px;
}

.flaschenpost-logo {
    width: 100%;
    max-width: 450px;
}

.rewe-logo {
    width: 100%;
    max-width: 200px;
}
</style>
