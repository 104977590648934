var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", {}, [
    _vm._m(0),
    _vm._v(" "),
    _setup.submitted < 1
      ? _c("div", [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "form",
                {
                  staticClass: "textColor mb-5 mx-auto",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _setup.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-12 col-md" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "inputName1" } }, [
                          _vm._v("Vorname *"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _setup.form.vorname,
                              expression: "form.vorname",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "inputName1",
                            required: "",
                          },
                          domProps: { value: _setup.form.vorname },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _setup.form,
                                "vorname",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-md" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "inputName2" } }, [
                          _vm._v("Nachname *"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _setup.form.nachname,
                              expression: "form.nachname",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "inputName2",
                            required: "",
                          },
                          domProps: { value: _setup.form.nachname },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _setup.form,
                                "nachname",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "inputStreet1" } }, [
                          _vm._v("Straße *"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _setup.form.strasse,
                              expression: "form.strasse",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "inputStreet1",
                            required: "",
                          },
                          domProps: { value: _setup.form.strasse },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _setup.form,
                                "strasse",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "inputZip1" } }, [
                          _vm._v("PLZ *"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _setup.form.plz,
                              expression: "form.plz",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "inputZip1",
                            required: "",
                          },
                          domProps: { value: _setup.form.plz },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_setup.form, "plz", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "inputCity1" } }, [
                          _vm._v("Ort *"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _setup.form.ort,
                              expression: "form.ort",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "inputCity1",
                            required: "",
                          },
                          domProps: { value: _setup.form.ort },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_setup.form, "ort", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "inputEmail1" } }, [
                      _vm._v("E-Mail Adresse *"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _setup.form.email,
                          expression: "form.email",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "email", id: "inputEmail1", required: "" },
                      domProps: { value: _setup.form.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_setup.form, "email", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "inputTelefone1" } }, [
                          _vm._v("Telefonnummer"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _setup.form.telefon,
                              expression: "form.telefon",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", id: "inputTelefone1" },
                          domProps: { value: _setup.form.telefon },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _setup.form,
                                "telefon",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "inputAnswer1" } }, [
                          _vm._v(
                            "Wo sollen wir dich und deine Freunde besuchen? *"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _setup.form.antwort,
                              expression: "form.antwort",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { rows: "5", id: "inputAnswer", required: "" },
                          domProps: { value: _setup.form.antwort },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _setup.form,
                                "antwort",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "form-check" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _setup.form.tnb,
                            expression: "form.tnb",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          type: "checkbox",
                          id: "gridCheck",
                          required: "",
                        },
                        domProps: {
                          checked: Array.isArray(_setup.form.tnb)
                            ? _vm._i(_setup.form.tnb, null) > -1
                            : _setup.form.tnb,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _setup.form.tnb,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _setup.form,
                                    "tnb",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _setup.form,
                                    "tnb",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_setup.form, "tnb", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "gridCheck" },
                        },
                        [
                          _vm._v(
                            "\n                                Ich habe die\n                                "
                          ),
                          _c(
                            "router-link",
                            {
                              staticStyle: { "text-decoration": "underline" },
                              attrs: {
                                to: { name: "bulliGewinnspielTnb" },
                                target: "_blank",
                              },
                            },
                            [_vm._v("Teilnahme­bedingungen")]
                          ),
                          _vm._v(
                            "\n                                zur Teilnahme am Gewinnspiel gelesen und stimme\n                                ihnen zu. *\n                            "
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "form-check" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _setup.form.privacy,
                            expression: "form.privacy",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          type: "checkbox",
                          id: "gridCheck2",
                          required: "",
                        },
                        domProps: {
                          checked: Array.isArray(_setup.form.privacy)
                            ? _vm._i(_setup.form.privacy, null) > -1
                            : _setup.form.privacy,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _setup.form.privacy,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _setup.form,
                                    "privacy",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _setup.form,
                                    "privacy",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_setup.form, "privacy", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "gridCheck2" },
                        },
                        [
                          _vm._v(
                            "\n                                Ich habe die Datenschutz­hinweise zur Teilnahme am Gewinnspiel gelesen und bin\n                                mit\n                                deren Verwendung sowie Speicherung meiner Daten einverstanden. *\n                            "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-block",
                      attrs: { type: "submit", disabled: _setup.submitting },
                    },
                    [_vm._v("Teilnehmen")]
                  ),
                  _vm._v(" "),
                  _c("small", { staticClass: "form-text text-muted mt-3" }, [
                    _vm._v(
                      "\n                        *Pflichtfelder\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _setup.submitted == 1 ? _c("div", [_vm._m(2)]) : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "header-container my-3" }, [
      _c("img", {
        attrs: {
          src: require("../images/VE_23_PK_Bilder_Stoerer_01.png"),
          width: "568",
          height: "377",
          alt: "Komm wir trinken noch ein Pülleken",
          title: "Komm wir trinken noch ein Pülleken",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "container my-3" }, [
      _c("div", { staticClass: "quote-wrapper" }, [
        _c("div", { staticClass: "col-md-9", attrs: { id: "quoteVeltins" } }, [
          _c("h2", { attrs: { id: "titleNormal" } }, [
            _vm._v("Jetzt am Gewinnspiel teilnehmen!"),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "textColor" }, [
            _vm._v("\n                        Unseren Pülleken Bulli?"),
            _c("br"),
            _vm._v("Kannst du haben!\n                    "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "textColor" }, [
            _vm._v(
              "\n                        Denn mit etwas Glück macht unser VW T2 Bulli schon bald bei dir Halt. Natürlich mit ordentlich\n                        Pülleken an Board!\n                    "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "textColor" }, [
            _vm._v(
              "\n                        Verrate uns dazu einfach wo wir dich und deine Freunde mit unserem gut gefüllten Pülleken Bulli\n                        besuchen sollen.\n                    "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "textColor" }, [
            _vm._v(
              "\n                        Teilnahmeschluss: 31.08.2023\n                    "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "container my-3" }, [
      _c("div", { staticClass: "quote-wrapper" }, [
        _c("div", { staticClass: "col-md-9", attrs: { id: "quoteVeltins" } }, [
          _c("h2", { attrs: { id: "titleNormal" } }, [
            _vm._v("Vielen Dank für Deine Teilnahme."),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "textColor" }, [
            _vm._v(
              "\n                        Nach Beendigung der Aktion wird der Gewinner per Zufall gezogen. Dieser wird umgehend darüber\n                        informiert.\n                    "
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }