var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container pb-5 pb-md-0" }, [
      _c("div", { staticClass: "row mt-5 mt-md-0" }, [
        _c(
          "div",
          {
            staticClass:
              "col-6 pt-5 d-flex align-items-center justify-content-center mx-auto",
          },
          [
            _c("img", {
              attrs: {
                src: require("../../images/VE_Puelleken_Gebinde_Impressum.png"),
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-12" }, [
          _c("div", { staticClass: "impressum-container" }, [
            _c("div", { staticClass: "textwidget" }, [
              _c("h2", [
                _vm._v(
                  "Verlosung eines Besuch des Pülleken VW T2 Bullis inkl. 13 Kisten 24x0,33l Pülleken"
                ),
                _c("br"),
                _vm._v(
                  "\n                        Teilnahmebedingungen und Spielregeln:\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Grundsätzlich gilt:\n                    ")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Diese Aktion steht in keiner Verbindung zu Facebook und wird in keiner Weise von Facebook\n                        gesponsert, unterstützt oder organisiert. Veranstalter des Gewinnspiels ist nicht Facebook,\n                        sondern die Brauerei C. & A. VELTINS GmbH & Co. KG. Für den Fall, dass Dritten im Zusammenhang\n                        mit der Teilnahme am Gewinnspiel Ansprüche (z. B. Schadensersatz- oder Unterlassungsansprüche)\n                        gegen die Brauerei C. & A. VELTINS GmbH & Co. KG stellen sollten, stellt der Teilnehmer die\n                        Brauerei C. & A. Veltins GmbH & Co. KG von diesen Ansprüchen frei. "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Wie machst Du mit?")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Du kannst an dem Gewinnspiel teilnehmen, wenn Du mind. 18 Jahre alt bist und Deinen Wohnsitz in\n                        Deutschland hast. Teilnahmeberechtigt sind alle Personen, die über die Domain\n                        https://www.puelleken.de/bulli-gewinnspiel/ alle erforderlichen Daten angegeben haben. Die\n                        Teilnahme mit gefälschten Identitäten oder mit Identitäten von Drittpersonen ist nicht erlaubt.\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Der Teilnahmebeitrag besteht darin, den QR-Code in dem Magazin „Motor-Klassik“ abzuscannen, die\n                        erforderlichen persönlichen Daten anzugeben und uns in dem vorgesehenen Antwortfeld zu verraten,\n                        mit wem du dich über den Besuch unseres Pülleken VW T2 Bullis freuen würdest."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Keinesfalls dürfen Rechte von Personen verletzt werden, weswegen anstößige, unsittliche,\n                        beleidigende oder sonst gegen geltendes Recht verstoßende Posts verboten sind und von der\n                        Teilnahme ausgeschlossen. Du bestätigst, dass die genannten Personen mit ihrer Nennung\n                        einverstanden sind."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "VELTINS behält sich vor, Teilnehmer vom Wettbewerb auszuschließen oder den Gewinn nicht\n                        auszugeben, wenn die Teilnahmebedingungen nicht eingehalten sind. Mit der Teilnahme an dem\n                        Gewinnspiel akzeptierst Du diese Teilnahmebedingungen. "
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("Die Teilnahme ist kostenlos. ")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Jede Person kann genau einmal an der Verlosung teilnehmen. "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Was kannst Du gewinnen?")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Die Brauerei C. & A. VELTINS GmbH & Co. KG vergibt unter allen Teilnehmern 1 x 1 Besuch des\n                        Pülleken VW T2 Bullis inkl. 13 Kisten 24x0,33l Pülleken."
                ),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Wie lange läuft das Gewinnspiel?")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Das Gewinnspiel endet am Donnerstag, den 31.08.2023, 23:59 Uhr. "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Wie wird über den Gewinn entschieden?")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Nach Beendigung der Aktion wird der Gewinner per Zufall gezogen. Dieser wird umgehend darüber\n                        informiert. Die Gewinner werden im Folgenden gebeten, über ein Anmeldeformular ihren Namen an\n                        die Brauerei C. & A. VELTINS GmbH & Co. KG mitzuteilen. "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Bestätigt ein Gewinner die Annahme des Gewinnangebots nicht innerhalb einer Frist von 24 Stunden,\n                        erlischt das Gewinnangebot. Sollten die angegebenen Kontaktmöglichkeiten fehlerhaft sein, ist\n                        die Brauerei C. & A. VELTINS GmbH & Co. KG nicht verpflichtet, richtige Adressen auszuforschen.\n                        Die Nachteile, die sich aus der Angabe fehlerhafter Kontaktdaten oder Übermittlungsfehlern\n                        ergeben, gehen zu Lasten des Teilnehmers. "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "VELTINS behält sich vor, die Teilnahmebedingungen auch während der Laufzeit des Spiels anzupassen\n                        oder das Spiel abzubrechen. Nach einer Änderung gelten die neuen Bedingungen. Grund für einen\n                        Abbruch kann sein, wenn der Verdacht entsteht, dass das Spiel manipuliert wurde oder eingegebene\n                        Daten in nicht unwesentlichem Umfang nicht der Wahrheit entsprechen oder der Post irgendwie\n                        Rechte Dritter beeinträchtigt. Ob Gründe für einen Abbruch oder Ausschluss vorliegen,\n                        entscheidet VELTINS nach freiem Ermessen. "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v(
                  "Wofür haftet die Brauerei C. & A. VELTINS GmbH & Co. KG?"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Die Brauerei C. & A. VELTINS GmbH & Co. KG kann für jegliche technischen Störungen nicht\n                        verantwortlich gemacht werden. Für Datenverluste insbesondere im Wege der Datenübertragung und\n                        jedwede technischen Defekte wird keine Haftung übernommen. Die Teilnahme am Gewinnspiel\n                        begründet keinen Anspruch auf einen Gewinn. Der Rechtsweg ist ausgeschlossen. "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Datenschutzbestimmungen")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Wir verweisen insgesamt auf unsere Datenschutzhinweise, die Du unter\n                        https://www.veltins.de/datenschutz/ abrufen kannst; diese gelten auch hier. "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Du erklärst Dich weiter damit einverstanden, dass VELTINS Daten über Dich erhebt, in dem Umfang,\n                        in dem Du sie zur Verfügung stellst und dass dieses Daten für die Zwecke dieses Gewinnspiels\n                        verarbeitet werden. Du stimmst außerdem zu, dass im Falle eines Gewinns VELTINS Dich per\n                        Instagram-Post und ggfs. privater Nachricht bei Instagram über den Gewinn informiert sowie die\n                        von Dir übermittelten Daten zur Kontaktaufnahme und Zusendung des Gewinns nutzt und die von Dir\n                        übermittelten Daten durch die Brauerei C. & A. VELTINS GmbH & Co. KG für die Abwicklung der\n                        Aktion verarbeitet werden. Die gespeicherten Daten werden nicht zu Werbezwecken genutzt und\n                        nicht an unbefugte Dritte weitergegeben. Nach endgültiger Abwicklung der Aktion werden die\n                        gespeicherten Daten gelöscht. "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Der Datenverarbeitung legen wir die geltende Rechtslage, insbesondere die EU-DSGVO, zu Grunde und\n                        halten diese ein. "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Jeder Teilnehmer kann jederzeit die Löschung seiner eingegebenen Daten verlangen. Er nimmt nach\n                        der Löschung nicht mehr an dem Gewinnspiel teil. Wenn ein Teilnehmer die Löschung verlangt,\n                        entfernt VELTINS dessen Daten vom Server. Für die Löschung der Daten ist eine E-Mail an\n                        veltinsinfo@veltins.de oder eine schriftliche Nachricht an die Brauerei C. & A. VELTINS GmbH &\n                        Co. KG, An der Streue, 59872 Meschede-Grevenstein, mit einem ausdrücklichen Wunsch auf Löschung\n                        erforderlich. Soweit einer Löschung gesetzliche, satzungsmäßige oder vertragliche\n                        Aufbewahrungsfristen entgegenstehen, werden die betreffenden Daten gesperrt. "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Die Übermittlung von Daten an die Brauerei C. & A. VELTINS GmbH & Co. KG erfolgt für dieses\n                        Gewinnspiel via einer eigens dafür erstellten Domain\n                        (https://www.puelleken.de/bulli-gewinnspiel/)."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("\n                        Stand: 20.07.2023"),
                _c("br"),
                _vm._v(
                  "\n                        Brauerei C. & A. Veltins GmbH & Co. KG"
                ),
                _c("br"),
                _vm._v("\n                        An der Streue"),
                _c("br"),
                _vm._v("\n                        59872 Meschede-Grevenstein"),
                _c("br"),
                _vm._v(
                  "\n                        Telefon: +49 - 29 34 - 959 - 0"
                ),
                _c("br"),
                _vm._v(
                  "\n                        Fax: +49 - 29 34 - 959 - 493"
                ),
                _c("br"),
                _vm._v(
                  "\n                        E-Mail: veltinsinfo@veltins.de\n                    "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }