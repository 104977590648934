import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster' // replace src with dist if you have Babel issues

import Shopfinder from './components/Map.vue'
import Instagram from './components/Instagram.vue'

import MainPage from './pages/MainPage.vue'
import HeaderDefault from './components/HeaderDefault.vue'
const Impressum = () => import(/* webpackChunkName: "group-impressum" */ './pages/Impressum.vue');
const BulliGewinnspiel = () => import(/* webpackChunkName: "group-bulliGewinnspiel" */ './pages/BulliGewinnspiel.vue');
const BulliGewinnspielTnb = () => import(/* webpackChunkName: "group-bulliGewinnspiel" */ './pages/BulliGewinnspiel/Tnb.vue');
const HeaderNoBadge = () => import(/* webpackChunkName: "group-bulliGewinnspiel" */ './components/HeaderNoBadge.vue');
const Datenschutz = () => import(/* webpackChunkName: "group-datenschutz" */ './pages/Datenschutz.vue');
const NotFoundComponent = () => import(/* webpackChunkName: "group-notFound" */ './pages/notFound.vue');

import axios from 'axios'

// nur das benötigte modul "modal" hinzufügen
import 'bootstrap/js/dist/modal';

import './scss/style.scss';

Vue.prototype.axios = axios

if(document.cookie.indexOf('_vcc') > -1){
    Vue.use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyC3XxTivDGNbi5NxntaUPllHDi6rVAMQvI',
            libraries: 'places', // necessary for places input
        },
    })
}
window.addEventListener('submitConsent', ()=>{
    if(document.cookie.indexOf('_vcc') > -1){
        window.location.reload();
    }
})

Vue.use(VueRouter)

Vue.component('gmap-cluster', GmapCluster)
Vue.component('Shopfinder', Shopfinder)
Vue.component('VE-Instagram-Images', Instagram)

const routes = [
    { path: '', components: {default: MainPage, header: HeaderDefault} },
    { path: '/shopfinder', name: 'shopfinder', components: {default: MainPage, header: HeaderDefault} },
    { path: '/datenschutz', name: 'datenschutz', components: {default: Datenschutz, header: HeaderDefault} },
    { path: '/impressum', name: 'impressum', components: {default: Impressum, header: HeaderDefault} },
    { path: '/bulli-gewinnspiel', name: 'bulliGewinnspiel', components: {default: BulliGewinnspiel, header: HeaderNoBadge} },
    { path: '/bulli-gewinnspiel/tnb', name: 'bulliGewinnspielTnb', components: {default: BulliGewinnspielTnb, header: HeaderNoBadge} },
    { path: '*', components: {default: NotFoundComponent, header: HeaderDefault} }
];


const router = new VueRouter({
    mode: 'hash',
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if(to.name && to.name == 'shopfinder'){
            setTimeout(() => {
                var el = document.getElementById('buy-puelleken');
                if (el) {
                    window.scrollTo(0, el.offsetTop)
                }
            });
        }

        if (savedPosition) {
            return savedPosition
        }

        if (to.hash) {
            return { selector: to.hash }
        }

        return { x: 0, y: 0 }
    }
})

new Vue({
    el: '#app',
    template: '<App/>',
    components: {
        App,
    },
    router,
});