var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "fluid-container" },
      [
        _c("router-view", { attrs: { name: "header" } }),
        _vm._v(" "),
        _c("router-view"),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticStyle: { "z-index": "2" } }, [
      _c(
        "footer",
        { staticClass: "text-center", staticStyle: { color: "white" } },
        [
          _c("div", { staticStyle: { "background-color": "#006728" } }, [
            _c("div", { staticClass: "row" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "col footerElement" }, [
                _c(
                  "div",
                  { staticClass: "row justify-content-between w-100" },
                  [
                    _c(
                      "div",
                      { staticClass: "links" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "mr-md-3",
                            attrs: { to: { name: "impressum" } },
                          },
                          [_vm._v("Impressum")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "mr-md-3",
                            attrs: {
                              href: "https://www.veltins.de/verbraucherinformationen/",
                              target: "_blank",
                              rel: "noopener",
                            },
                          },
                          [_vm._v("Verbraucherinformationen")]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "mr-md-3",
                            attrs: { to: { name: "datenschutz" } },
                          },
                          [_vm._v("Datenschutz")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "mr-md-3",
                            attrs: {
                              href: "https://www.veltins.de/kontakt/",
                              target: "_blank",
                              rel: "noopener",
                            },
                          },
                          [_vm._v("Kontakt")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "mr-md-3",
                            attrs: {
                              href: "https://www.veltins.de/fachkunden/",
                              target: "_blank",
                              rel: "noopener",
                            },
                          },
                          [_vm._v("Fachkunden")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-5 col-12 mt-3 mt-md-0 copyright" },
                      [
                        _vm._v(
                          "\n                                © 2023 Brauerei C. A. VELTINS GmbH & Co. KG\n                            "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "ml-4 d-flex footer-images" }, [
      _c("div", [
        _c(
          "a",
          {
            attrs: {
              href: "http://www.bier-erst-ab-16.de/",
              target: "_blank",
              rel: "noopener",
            },
          },
          [
            _c("img", {
              staticClass: "img-fluid center-img",
              attrs: {
                src: require("./images/Logo-Bier-erst-ab-16.png"),
                alt: "Bier? ab 16",
                title: "Bier? ab 16",
                width: "100",
                height: "100",
                loading: "lazy",
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "a",
          {
            attrs: {
              href: "https://ddad.de/",
              target: "_blank",
              rel: "noopener",
            },
          },
          [
            _c("img", {
              staticClass: "img-fluid center-img",
              attrs: {
                src: require("./images/DDAD.jpg"),
                alt: "ddad",
                title: "ddad",
                width: "100",
                height: "100",
                loading: "lazy",
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "a",
          {
            attrs: {
              href: "https://www.bierbewusstgeniessen.de/",
              target: "_blank",
              rel: "noopener",
            },
          },
          [
            _c("img", {
              staticClass: "img-fluid center-img",
              attrs: {
                src: require("./images/Bier_bewusst_geniessen.png"),
                alt: "Bier bewusst genießen",
                title: "Bier bewusst genießen",
                width: "100",
                height: "100",
                loading: "lazy",
              },
            }),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }