var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container pb-5 pb-md-0" }, [
      _c("div", { staticClass: "row mt-5 mt-md-0" }, [
        _c(
          "div",
          {
            staticClass:
              "col-6 pt-5 d-flex align-items-center justify-content-center",
          },
          [
            _c("div", [
              _c("h1", [_vm._v("404 Seite nicht gefunden")]),
              _vm._v(" "),
              _c("p", [
                _c(
                  "a",
                  { staticClass: "btn btn-primary", attrs: { href: "/" } },
                  [_vm._v("zur Startseite")]
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [
          _c("img", {
            attrs: {
              src: require("../images/VE_Puelleken_Gebinde_Impressum.png"),
            },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }