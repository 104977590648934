var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container buy-puelleken-container" }, [
    _c(
      "div",
      { staticClass: "row ig-images" },
      _vm._l(_vm.igitems, function (item, index) {
        return _c("div", { key: index, staticClass: "col-md-3 col-6" }, [
          _c("div", [
            _c(
              "a",
              {
                attrs: {
                  href: item.permalink,
                  target: "_blank",
                  rel: "noopener",
                },
              },
              [
                _c("img", {
                  key: item.media_url,
                  staticClass: "img-fluid center-img",
                  class: "IG_TYPE_" + item.media_type,
                  attrs: {
                    src: item.thumbnail_url || item.media_url,
                    alt: "Image from Instagram",
                    width: "150",
                    height: "130",
                    loading: "lazy",
                  },
                  on: { load: _vm.loaded },
                }),
              ]
            ),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }