var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "container buy-puelleken-container",
      attrs: { id: "buy-puelleken" },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass:
              "col-lg-5 col-xl-4 col-12 d-flex justify-content-center mb-5",
          },
          [
            _c(
              "div",
              { staticClass: "text-center socialRotation" },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("p", { staticClass: "mt-3 buy-puelleken" }, [
                  _vm._v(
                    "\n                    Hier können Sie unser helles Pülleken kaufen:\n                    "
                  ),
                ]),
                _c("div", { staticClass: "btn-group-toggle" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "btn btn-outline-primary w-auto text-uppercase mr-3",
                      class: {
                        active: _vm.PUELLEKEN_STORE_ID == _vm.checkedShopType,
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.checkedShopType,
                            expression: "checkedShopType",
                          },
                        ],
                        attrs: { type: "radio", name: "shopType" },
                        domProps: {
                          value: _vm.PUELLEKEN_STORE_ID,
                          checked: _vm._q(
                            _vm.checkedShopType,
                            _vm.PUELLEKEN_STORE_ID
                          ),
                        },
                        on: {
                          change: function ($event) {
                            _vm.checkedShopType = _vm.PUELLEKEN_STORE_ID
                          },
                        },
                      }),
                      _vm._v(" Händler\n                        "),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "btn btn-outline-primary w-auto text-uppercase",
                      class: {
                        active: _vm.PUELLEKEN_GASTRO_ID == _vm.checkedShopType,
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.checkedShopType,
                            expression: "checkedShopType",
                          },
                        ],
                        attrs: { type: "radio", name: "shopType" },
                        domProps: {
                          value: _vm.PUELLEKEN_GASTRO_ID,
                          checked: _vm._q(
                            _vm.checkedShopType,
                            _vm.PUELLEKEN_GASTRO_ID
                          ),
                        },
                        on: {
                          change: function ($event) {
                            _vm.checkedShopType = _vm.PUELLEKEN_GASTRO_ID
                          },
                        },
                      }),
                      _vm._v(" Gastronomie\n                        "),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("p"),
                _vm._v(" "),
                _vm.cookiesEnabled
                  ? _c("gmap-autocomplete", {
                      staticClass: "storefinder-search-input",
                      attrs: {
                        placeholder: "PLZ oder Ort",
                        "select-first-on-enter": true,
                        options: {
                          componentRestrictions: { country: "de" },
                          strictBounds: true,
                          fields: ["geometry.location"],
                          types: ["geocode"],
                        },
                      },
                      on: { place_changed: _vm.setPlace },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-lg-7 col-12 ml-auto storefinder-col" },
          [
            _vm.cookiesEnabled
              ? _c(
                  "gmap-map",
                  {
                    staticClass: "storefinder",
                    staticStyle: { width: "100%", height: "400px" },
                    attrs: {
                      center: _vm.center,
                      zoom: _vm.zoom,
                      markers: _vm.markers,
                      options: _vm.mapOptions,
                    },
                  },
                  [
                    _c(
                      "gmap-cluster",
                      { attrs: { zoomOnClick: true } },
                      [
                        _vm._l(_vm.markers, function (m, index) {
                          return _c("gmap-marker", {
                            key: index,
                            attrs: { position: m.position, icon: m.icon },
                            on: {
                              click: function ($event) {
                                return _vm.toggleInfoWindow(m, index)
                              },
                            },
                          })
                        }),
                        _vm._v(" "),
                        _c("gmap-info-window", {
                          attrs: {
                            options: _vm.infoOptions,
                            position: _vm.infoWindowPos,
                            opened: _vm.infoWinOpen,
                            content: _vm.infoContent,
                          },
                          on: {
                            closeclick: function ($event) {
                              _vm.infoWinOpen = false
                            },
                          },
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _c("div", [
                  _vm._v("\n                Bitte die "),
                  _c(
                    "a",
                    {
                      staticStyle: { "text-decoration": "underline" },
                      attrs: { href: "#cookieconsent" },
                    },
                    [_vm._v("Cookie Einstellungen")]
                  ),
                  _vm._v(" anpassen.\n            "),
                ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "titleSocialMedia" }, [
      _c("span", { staticClass: "brand" }, [_vm._v("Pülleken")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }