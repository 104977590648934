var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "row header-wrapper" }, [
        _c(
          "h1",
          {
            staticClass: "col-xl-12 position-relative",
            attrs: { id: "header" },
          },
          [
            _c("a", { attrs: { href: "/" } }, [
              _c("img", {
                staticClass: "img-fluid",
                staticStyle: { display: "inline" },
                attrs: {
                  src: require("../images/Puelleken_logo_navi_maenner.png"),
                  alt: "Pülleken",
                  title: "Pülleken",
                  width: "430",
                  height: "234",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "brand" }, [_vm._v("Pülleken")]),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("a", { attrs: { href: "/#/shopfinder" } }, [
        _c("img", {
          staticClass: "img-fluid banner",
          attrs: {
            src: require("../images/VE_Puelleken_Stoerer.png"),
            alt: "Shopfinder",
            title: "Händler finden",
            width: "135",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }