<template>
    <div class="">
        <div class="header-container my-3">
            <img src="../images/VE_23_PK_Bilder_Stoerer_01.png" width="568" height="377"
                alt="Komm wir trinken noch ein Pülleken" title="Komm wir trinken noch ein Pülleken" />
        </div>

        <div v-if="submitted < 1">
            <div class="container my-3">
                <div class="quote-wrapper">
                    <div class="col-md-9" id="quoteVeltins">
                        <h2 id="titleNormal">Jetzt am Gewinnspiel teilnehmen!</h2>
                        <p class="textColor">
                            Unseren Pülleken Bulli?<br />Kannst du haben!
                        </p>
                        <p class="textColor">
                            Denn mit etwas Glück macht unser VW T2 Bulli schon bald bei dir Halt. Natürlich mit ordentlich
                            Pülleken an Board!
                        </p>
                        <p class="textColor">
                            Verrate uns dazu einfach wo wir dich und deine Freunde mit unserem gut gefüllten Pülleken Bulli
                            besuchen sollen.
                        </p>
                        <p class="textColor">
                            Teilnahmeschluss: 31.08.2023
                        </p>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <form class="textColor mb-5 mx-auto" @submit.prevent="submit">
                        <div class="form-row">
                            <div class="col-12 col-md">
                                <div class="form-group">
                                    <label for="inputName1">Vorname *</label>
                                    <input type="text" class="form-control" id="inputName1" required v-model="form.vorname">
                                </div>
                            </div>
                            <div class="col-12 col-md">
                                <div class="form-group">
                                    <label for="inputName2">Nachname *</label>
                                    <input type="text" class="form-control" id="inputName2" required
                                        v-model="form.nachname">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="inputStreet1">Straße *</label>
                                    <input type="text" class="form-control" id="inputStreet1" required
                                        v-model="form.strasse">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="inputZip1">PLZ *</label>
                                    <input type="text" class="form-control" id="inputZip1" required v-model="form.plz">
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="inputCity1">Ort *</label>
                                    <input type="text" class="form-control" id="inputCity1" required v-model="form.ort">
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="inputEmail1">E-Mail Adresse *</label>
                            <input type="email" class="form-control" id="inputEmail1" required v-model="form.email">
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="inputTelefone1">Telefonnummer</label>
                                    <input type="text" class="form-control" id="inputTelefone1" v-model="form.telefon">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="inputAnswer1">Wo sollen wir dich und deine Freunde besuchen? *</label>
                                    <textarea class="form-control" rows="5" id="inputAnswer" required
                                        v-model="form.antwort"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="gridCheck" required v-model="form.tnb">
                                <label class="form-check-label" for="gridCheck">
                                    Ich habe die
                                    <router-link :to="{ name: 'bulliGewinnspielTnb' }" target="_blank"
                                        style="text-decoration: underline;">Teilnahme­bedingungen</router-link>
                                    zur Teilnahme am Gewinnspiel gelesen und stimme
                                    ihnen zu. *
                                </label>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="gridCheck2" required
                                    v-model="form.privacy">
                                <label class="form-check-label" for="gridCheck2">
                                    Ich habe die Datenschutz­hinweise zur Teilnahme am Gewinnspiel gelesen und bin
                                    mit
                                    deren Verwendung sowie Speicherung meiner Daten einverstanden. *
                                </label>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block" :disabled="submitting">Teilnehmen</button>
                        <small class="form-text text-muted mt-3">
                            *Pflichtfelder
                        </small>
                    </form>
                </div>
            </div>
        </div>
        <div v-if="submitted == 1">
            <div class="container my-3">
                <div class="quote-wrapper">
                    <div class="col-md-9" id="quoteVeltins">
                        <h2 id="titleNormal">Vielen Dank für Deine Teilnahme.</h2>
                        <p class="textColor">
                            Nach Beendigung der Aktion wird der Gewinner per Zufall gezogen. Dieser wird umgehend darüber
                            informiert.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import axios from 'axios';
import { ref } from 'vue';

const form = ref({});
const submitting = ref(false);
const submitted = ref(0);

const submit = () => {
    if (submitting.value) {
        return;
    }
    submitting.value = true;
    axios.postForm('/php/mail.php', form.value, {
        headers: {
            'token': 'c02c7f29-d338-447c-a5c5-60ae88622734'
        }
    })
        .then(function (res) {
            submitted.value = 1;
        }).finally(() => {
            submitting.value = false;
        });
}

</script>