<template>
    <div class="container buy-puelleken-container">
        <div class="row ig-images">
            <div class="col-md-3 col-6" v-for="(item, index) in igitems" :key="index">
                <div>
                    <a v-bind:href="item.permalink" target="_blank" rel="noopener">
                        <img :src="item.thumbnail_url||item.media_url" :key="item.media_url" class="img-fluid center-img" :class="'IG_TYPE_'+item.media_type" alt="Image from Instagram" width="150" height="130" @load="loaded" loading="lazy"/>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import '../scss/_variables.scss';

.ig-images{
    img{
        opacity: 0;
        transform: scale(1.4);
        transition: all 1s;
        &.loaded{
        opacity: 1;
        transform: scale(1);
        }
    }
}
</style>

<script>
import Vue from 'vue'

const TOKEN_URL = 'https://www.puelleken.de/oauth/token.php'
const INSTAGRAM_ENDPOINT = 'https://graph.instagram.com/me/media'
const INSTAGRAM_FIELDS = 'thumbnail_url,media_url,media_type,permalink'
const NUMBER_OF_IMAGES = 8

export default Vue.component('Instagram', {
    data: function () {
        var default_igitems = [];
        for(var i=1; i<=NUMBER_OF_IMAGES; i++){
            default_igitems.push({permalink: 'https://www.instagram.com/puelleken/', media_url: require('../images/pic' + i + '.jpg')});
        }
        return {
            igitems: default_igitems,
            ig_token: null
        }
    },

    mounted: async function () {
        if(!this.ig_token && this.cookiesEnabled){
            this.ig_token = await this.getToken();
        }
        this.fetchMedia();
    },

    methods: {
        getToken: function () {
                return this.axios
                    .get(TOKEN_URL)
                    .then((result) => {
                        if (!result.data) {
                            throw 'kein instagram token erhalten'
                        }
                        return result.data;
                    })
                    .catch(function (error) {
                        console.error(error)
                    });
        },
        fetchMedia: function () {
            if(!this.ig_token){return}
            var self = this
            this.axios
                .get(INSTAGRAM_ENDPOINT, {
                    params: {
                        fields: INSTAGRAM_FIELDS,
                        access_token: this.ig_token,
                    },
                })
                .then(function (data) {
                    self.set_igitems(data.data.data);
                })
                .catch(function (error) {
                    console.error(error)
                })
        },
        set_igitems: function (data) {
            this.igitems = data.slice(0, NUMBER_OF_IMAGES)
        },
        loaded: function(e){
            e.target.classList.add('loaded');
        }
    },
    computed:{
        cookiesEnabled: ()=>{
            return document.cookie.indexOf('_vcc') > -1
        }
    }
})
</script>